.layout {
  text-align: center;
  background: linear-gradient(180deg, #282c34 0%, hsl(220, 13%, 5%) 100%);
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

a {
  background: linear-gradient(105deg, #3BDA57 38%, #20aa37 98%);
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
}

a:hover {
  background: linear-gradient(105deg, hsl(131, 68%, 74%) 38%, hsl(130, 68%, 60%) 98%);
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
}