.layout {
  text-align: center;
  background: linear-gradient(180deg, #282c34 0%, hsl(220, 13%, 5%) 100%);
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.button {
  padding: 1rem 2rem;
  /* background: linear-gradient(105deg, #3BDA57 38%, #168F2B 98%); */
  background: linear-gradient(105deg, hsl(131, 68%, 44%) 38%, hsl(130, 73%, 22%) 98%);

  border-radius: 0.4rem;

  cursor: pointer;
  user-select: none;
  font-weight: bolder;
  transition: all 0.3s ease-out;
}

.button:hover {
  box-shadow: 0px 0px 18px 1px rgba(65, 156, 43, 0.65);
}

.button:active {
  background: linear-gradient(105deg, #3BDA57 38%, #168F2B 98%);
  /* background: linear-gradient(105deg, hsl(131, 68%, 44%) 38%, hsl(130, 73%, 22%) 98%); */
}

.wallet-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wallet-details {
  text-align: left;
}

.connection-details {
  margin-top: 3rem;
  display: flex;
  width: 80vw;
  justify-content: space-around;
}

.connections {}

.sticky {
  position: sticky;
  top: 1.33rem;
}

.pairings,
.sessions,
.events {
  padding: 1rem;
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 2rem;

}

.events {
  max-height: 400px;
}

.item {
  background-color: rgb(35, 38, 41);
  border: 2px solid rgb(35, 38, 41);

  padding: 1rem;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  gap: 1rem;
  word-break: break-word;
}

.active-item {
  border: 2px solid rgba(36, 188, 64, 0.6);
  background-color: rgb(35, 38, 41);
}

.pairing-item {}

.session-item {}

.event-item {}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(83, 94, 104);
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.back-link {
  position: absolute;
  font-weight: bolder;
  top: 1rem;
  left: 1rem;
}

.back-link a {
  color: white !important;
  text-decoration: none !important;
}

.disconnect-button {
  font-weight: bolder;
  font-family: cursive;
  color: rgb(223, 85, 85);
  border-radius: 300px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}

.disconnect-button:hover {
  color: white;
  background-color: rgb(255, 0, 0);
}

.refresh-button {
  font-weight: bolder;
  font-family: cursive;
  color: rgb(51, 197, 88);
  border-radius: 300px;
  position: absolute;
  right: 1rem;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}

.refresh-button:hover {
  background: linear-gradient(105deg, hsl(131, 68%, 34%) 38%, hsl(130, 73%, 22%) 98%);
}

.refresh-button:active {
  background: linear-gradient(105deg, hsl(131, 68%, 44%) 38%, hsl(130, 73%, 22%) 98%);
}